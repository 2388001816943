<!-- Navbar -->

<div class="navbar-area fixed-top">
    <div class="main-responsive-nav">
        <div class="container">
            <div class="main-responsive-menu"  >
                <div class="logo" style=" 
                right: 8px;left: auto;text-align: center;text-indent: 0px;font-size: 18px;position: fixed;top:3px;
                ">
                  <a routerLink="/Admin/Home" style=" right: 8px;left: auto;top:3px;">
                    <img src="/assets/img/Logo/35.webp"   alt="image">  
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar" style="background-color: rgb(208 184 184 / 20%);">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                                <a class="navbar-brand" [routerLink]="['Home']"><img src="/assets/img/Logo/90.webp" style="position: fixed;top:4px;" alt="image"></a>
                               
                <div class="collapse navbar-collapse mean-menu" style="margin-right: 7%;" id="navbarSupportedContent">
                
                    <ul class="navbar-nav"  *ngIf="isAdmin ||isSuperAdmin"> 
                        <li class="nav-item"><a routerLink="MainPageManagment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">  عن اللجنة  </a></li>
                        <li class="nav-item" ><a routerLink="Branch" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" > فروع التعليم الديني     </a></li> 
                        <li class="nav-item"><a routerLink="NewsAndArticals" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">الأخبار والمقالات</a></li>
                        <li class="nav-item"><a routerLink="Library/Libraries" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">المكتبة </a></li>
                        <li class="nav-item"><a routerLink="SpiritualEvents" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">الرياضات الروحية </a></li>
                        <li class="nav-item"><a routerLink="RelatedSites" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">  مواقع صديقة </a></li>
 


                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"> التحكم في المناهج <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu" style="    backdrop-filter: saturate(180%) blur(20px);">
                                  <li class="nav-item"><a routerLink="EducationalMethods" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">وسائل الإيضاح  </a></li> 
                                <li class="nav-item"><a routerLink="Lessons" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">اللقاءات  </a></li> 
                                <li class="nav-item"><a routerLink="Units" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">الوحدات  </a></li> 
                                <li class="nav-item"><a routerLink="Curriculas" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">المناهج  </a></li> 
                                <li class="nav-item"><a routerLink="EducationalStageList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">المراحل التعليمية  </a></li> 
                                                       <li class="nav-item"><a routerLink="EducationalClassList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">الصفوف الدراسية  </a></li> 
    
                            </ul>
                        </li>


                        <li class="nav-item" *ngIf="isSuperAdmin"><a routerLink="User/UsersManagment" class="nav-link"   routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" >  المستخدمين         </a></li>

                        <li class="nav-item" >
                            <a href="javascript:void(0)"  class="nav-link">  {{userName}} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu" style="    backdrop-filter: saturate(180%) blur(20px);">
                                <li class="nav-item"><a routerLink="ChangePassword" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> تغير الرقم السري  </a></li>
                                <li class="nav-item" #myNameElem><a class="nav-link"  id="logOut" routerLinkActive="active" routerLink="/Login" [routerLinkActiveOptions]="{exact: true}">  تسجيل الخروج     </a></li>  
                            </ul>
                        </li>



                    </ul>

                   

 


                </div>
            </nav>
        </div>
    </div>

     
</div>
<!-- End Navbar -->
