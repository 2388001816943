import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../Models/User';
  import { NotificationService } from './notification.service';
import { Router } from '@angular/router';     

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>; 
  constructor(private http: HttpClient,
   private notifyService:NotificationService, 
    private router:Router) {
  
  
  
      this.currentUserSubject = new BehaviorSubject<User>(localStorage.getItem('currentUser')?JSON.parse(localStorage.getItem('currentUser')):null);
   this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
     
      return this.currentUserSubject.value;
  }
   
  public set currentUserValue(v : User) {
    
    this.currentUserSubject = new BehaviorSubject<User>(v);
  }
  

    login(loginDto) {
      
        return this.http.post<any>(environment.baseUrl + '/api/Account/Login', loginDto).pipe(map(user => {
            
            if (!user.errorsNotUser) {
              
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                
                localStorage.token=user.token;
                localStorage.refreshToken=user.refreshToken;
                localStorage.currentUser=JSON.stringify(user);
                this.currentUserSubject.next(user);
            }
            return user;
        }));
    }

  logout() {
    
    this.http.post<any>(environment.baseUrl + '/api/Account/LogOut','').subscribe(
        res=>{  
      // remove user from local storage to log user out     
       localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("currentUser"); 
      
      this.currentUserSubject.next(null);
        },
        error=>{
          
           this.notifyService.showError("حدث خطاء برجاء مراجعه الدعم الفني ");
         
  
        }
      );
      
      
      
  }

}
