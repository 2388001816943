// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // baseUrl:'https://localhost:44321',
  // allowedDomains:'localhost:44321',
  // siteUrl:'https://localhost:4200',
 
  // AzureSorageContainerPath:'https://catholic.blob.core.windows.net/',
 
  // newsAndArticalsContainer:'newsandarticals',
  // mainContainer:'main' 
  production: true,
  baseUrl:'https://api.catholic-catechism-eg.com',
  allowedDomains:'https://api.catholic-catechism-eg.com',
  siteUrl:'https://control-panel.catholic-catechism-eg.com', /*angular*/
  
  AzureSorageContainerPath:'https://catholic.blob.core.windows.net/',

  newsAndArticalsContainer:'newsandarticals',
 mainContainer:'main'
};
 
