import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';   
import {  JwtModule } from "@auth0/angular-jwt";
import { JwtInterceptorInterceptor} from './Interceptors/jwt-interceptor.interceptor';  
 import { ErrorInterceptor } from './Interceptors/error.interceptor'; 
import { environment } from 'src/environments/environment';
import { MainComponent } from './main/main.component'; 
import { SharedModule } from './Modules/shared/shared.module';
 import { ArabicNumberPipePipe } from './pipe/arabic-number-pipe.pipe';
import { HeaderAndFooterModule } from './Modules/header-and-footer/header-and-footer.module';
 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
export function tokenGetter() {
return "";
  //  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,  
    MainComponent,   
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    HttpClientModule,  
    HeaderAndFooterModule,
    BrowserAnimationsModule,
     JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains:  [environment.allowedDomains],
        disallowedRoutes: []
      }
    }),  
   SharedModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerImmediately'
}), ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
}), 
  ],
  providers: [
     ArabicNumberPipePipe,
    { provide: HTTP_INTERCEPTORS,useClass: JwtInterceptorInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS,useClass: ErrorInterceptor, multi: true }, 
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
