import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import {   Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {  Router } from '@angular/router';
import { AuthenticationService } from '../Services/common/authentication.service';
import { NotificationService } from '../Services/common/notification.service';
 

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router:Router,private authService:AuthenticationService,
      private notifyService:NotificationService ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse) {
              
			   if(err.status === 401) {
					//   if 401 response returned from api
          
  this.router.navigate(['/Login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
 
				}
        else if(err.status === 403) {
          
           this.authService.logout();
          this.notifyService.showWarning("غير مسرح لهذا المستخدم بدخول هذه الصفحه");
            }
            else if(err.status === 400) {
              return throwError(err); 

                }
       return new  Observable<HttpEvent<any>>();
        //  return throwError(''); 
            }
        }) 
	)}
}