import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/Models/Role';
import { ArabicNumberPipePipe } from 'src/app/pipe/arabic-number-pipe.pipe';
import { AuthenticationService } from 'src/app/Services/common/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  address:string;
  tele:string;
  constructor(  private authService:AuthenticationService,
    private ArabicNumberPipe:ArabicNumberPipePipe
    ) {
    // this.address=ArabicNumberPipe.transform( "154 يوسف كرم و شبرا و القاهره ");
  this.tele=ArabicNumberPipe.transform( "01222727581");
   }

   get isAdmin() {
     
    return this.authService.currentUserValue && this.authService.currentUserValue?.role.indexOf(Role.Admin)>-1;
  }
  
  get isSuperAdmin() {
     
    return this.authService.currentUserValue &&this.authService.currentUserValue?.role.indexOf(Role.SuperAdmin)>-1;
  }

  ngOnInit(): void {
  }

}
