import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';import { FooterComponent } from 'src/app/components/common/footer/footer.component';
import { NavbarComponent } from 'src/app/components/common/navbar/navbar.component';

import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent, 
     
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    NavbarComponent,
    FooterComponent
  ]
})
export class HeaderAndFooterModule { }
