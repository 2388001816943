import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../Services/common/authentication.service';
 


@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor {
 
  constructor(private authService: AuthenticationService) { 
    
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    // add auth header with jwt if user is logged in and request is to the api url
     const currentUser = this.authService.currentUserValue;
     const isLoggedIn = currentUser && currentUser.token;
    
     const isApiUrl = request.url.startsWith(environment.baseUrl);
     
     if (isLoggedIn && isApiUrl) {
         request = request.clone({
             setHeaders: {
                Authorization: 'Bearer '+ currentUser.token
             }
         });
     } 
      
     return next.handle(request);
    }
}
