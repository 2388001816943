import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd ,ActivatedRoute, DefaultUrlSerializer, UrlTree, UrlSerializer} from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { buffer, filter, map, mergeMap } from 'rxjs/operators';
import {SEOService} from 'src/app/Services/seo.service'; 
// import {CustomUrlSerializer} from './custom-url-serializer';

declare let $: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,private seoService:SEOService,
    private activatedRoute:ActivatedRoute,private locationg: Location)
     { 
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) { 
     if(event.url!="/")
    {
      var newPath="";
    var paths=event.url.split("/");
    
    for (let index = 0; index < paths.length; index++) { 
       if(parseInt(paths[index])||paths[index]==='0')
       { 
          newPath=newPath+ btoa(paths[index])+'/';
       }
       else{
       newPath=newPath+paths[index]+'/';
       }
      } 
       newPath=newPath.replace('==','');
      
       this.locationg.replaceState(newPath);
      } 
    else{
           this.locationg.replaceState(event.url.toString());
        }
           
            }
        });
    }
  
    
      
   
    ngOnInit(){
     
        this.recallJsFuntions();
        this.setTitleAndMetaData(); 
    }
     recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {

              if(!event.url.includes('Login'))
              {
                $('.preloader').fadeIn('slow');
              }
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {

            $.getScript('../assets/js/main.js');//for server 
            $('.preloader').fadeOut('slow');
             this.location = this.router.url; 
                    if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    setTitleAndMetaData()
    {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
              while (route.firstChild) route = route.firstChild;
              return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
           )
           .subscribe((event) => {
             this.seoService.updateTitle(event['title']);
             //Updating Description and keywords tag dynamically with title
             this.seoService.updateTags(event['metatags']);
           });
    }
}