import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloaderComponent } from 'src/app/components/common/preloader/preloader.component';
 import { ToastrModule } from 'ngx-toastr';
import { ArabicNumberPipePipe } from 'src/app/pipe/arabic-number-pipe.pipe';
 

@NgModule({
  declarations: [
    PreloaderComponent, 
 ArabicNumberPipePipe
   
  ],
  imports: [
    CommonModule, 
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right', 
      autoDismiss:true,
      progressBar:true,
      enableHtml:true
    }),
    
  ],
  exports:[
     PreloaderComponent, 
 
     ArabicNumberPipePipe,
     ToastrModule, 
  ]
})
export class SharedModule { }
