import { Injectable } from '@angular/core';
import {Title,Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta,) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }
  updateTags(tags) {
    
    for (const tag in tags) {
  this.meta.updateTag({ name: tag, content: tags[tag] });
    }
  }
  
  updateTagDirect(tagNames,tagContents) {
    for (let index = 0; index < tagNames.length; index++) {
      this.meta.updateTag({ name: tagNames[index], content: tagContents[index] });
      
    } 
    
  }
    updateDescription(desc) { 
      
    this.meta.updateTag({ name: 'description', content:desc});
    }

    updateKeywords(keys) { 
      
      this.meta.updateTag({ name: 'keywords', content:keys});
      }
  
   
}
