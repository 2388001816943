import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'; 
import { Router } from '@angular/router';
import { ContentChange } from 'ngx-quill';
import { Role } from 'src/app/Models/Role';
import { User } from 'src/app/Models/User';
import { AuthenticationService } from 'src/app/Services/common/authentication.service';  
import { CrudServiceService } from 'src/app/Services/common/crud-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit  {
  @ViewChild("myNameElem") myNameElem: ElementRef;
  constructor( 
    private authService:AuthenticationService,private crudService:CrudServiceService, private router:Router ) { }

    searchResult:any;
    flag=false; 
  ngOnInit(): void {
    
  
  }
  get userName() {
    return this.authService.currentUserValue?.username;
  }

get isAdmin() {
     
  return this.authService.currentUserValue && this.authService.currentUserValue?.role.indexOf(Role.Admin)>-1;
}

get isSuperAdmin() {
   
  return this.authService.currentUserValue &&this.authService.currentUserValue?.role.indexOf(Role.SuperAdmin)>-1;
}
ngOnChanges(changes: SimpleChanges): void {
 
  console.log(this.myNameElem.nativeElement.innerHTML);
}
  
 
     searchOnKeyUp(event) {
      let input = event.target.value; 
      this.flag = false;
      if (input.length > 3) {
     
        this.crudService.getAll('Search/GetGlobalSearch?searchTerm='+input).subscribe(res=>{
          this.searchResult=res;
            if(this.searchResult.length>0)
            {
              this.flag = true;
            }
         });

      }
    } 

     
    search(term:string)
    {
      this.router.navigateByUrl("Site/Search/"+term);
    }

}
