import { stringify } from '@angular/compiler/src/util';
import { Pipe, PipeTransform } from '@angular/core';
import starkString from "starkstring";
@Pipe({
  name: 'arabicNumberPipe'
})
export class ArabicNumberPipePipe implements PipeTransform {
 
  transform(n): string {
    if (n === null || n === undefined) {
      return '';
    }

   //https://www.npmjs.com/package/starkstring
   
  return n= starkString(n) 
   .persianChar()
   .englishNumber()
    .arabicNumber()
  .toString();
      
  }
  convertNumbersToArabicInHtml(html):string
  {
    

    var map = 
    [
    "&\#1632;","&\#1633;","&\#1634;","&\#1635;","&\#1636;",
    "&\#1637;","&\#1638;","&\#1639;","&\#1640;","&\#1641;"
    ]

   return (html).replace(
  /\d(?=[^<>]*(<|$))/g,
  function($0) { return map[$0] });
  }
}