import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
 import { ErrorComponent } from './components/common/error/error.component';   
import { MainComponent } from './main/main.component';
 
 const routes: Routes = [   
 
    { path: '', loadChildren: () => import('./Modules/accout/accout.module').then(m => m.AccoutModule) }
  ,
   {path:'Admin',children: [
 
     { path: '', loadChildren: () => import('./Modules/control-panel/control-panel.module').then(m => m.ControlPanelModule) },
     { path: '', loadChildren: () => import('./Modules/home/home.module').then(m => m.HomeModule) },
     { path: '', loadChildren: () => import('./Modules/news-and-main/news-and-main.module').then(m => m.NewsAndMainModule) },
     { path: '', loadChildren: () => import('./Modules/look-ups/look-ups.module').then(m => m.LookUpsModule) },
     { path: '', loadChildren: () => import('./Modules/look-ups-list/look-ups-list.module').then(m => m.LookUpsListModule) },
     { path: '', loadChildren: () => import('./Modules/events-and-sites/events-and-sites.module').then(m => m.EventsAndSitesModule) },
     { path: '', loadChildren: () => import('./Modules/events-and-sites-list/events-and-sites-list.module').then(m => m.EventsAndSitesListModule) },
     { path: '', loadChildren: () => import('./Modules/educational/educational.module').then(m => m.EducationalModule) },
     { path: '', loadChildren: () => import('./Modules/educational-list/educational-list.module').then(m => m.EducationalListModule) },
    
  ],component:MainComponent},
    {path: '**', component: ErrorComponent}// This line will remain down from the whole pages component list

 ];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }